import React, { useState } from "react"
import { Classes, Icon } from "@blueprintjs/core";

const ServiceFavoriteButton = ({service, color = "#BE0000", icon = "lab-test", svg}) => {

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = { 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: color,
    backgroundColor: "white",
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "Montserrat, sans-serif",
    cursor : "default", 
    width: "250px", 
    borderRadius: "3px", 
    border: `2px solid ${color}`,
    gap: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  }

  const hoveredStyle = {
    ... buttonStyle,
    color: "white",
    backgroundColor: color,
    textDecoration: "none",
    border: `2px solid ${color}`,
  }

  let DangerIcon = ({ content }) => { return <span aria-hidden="true">
    <div style={{ width: 30, height: 30, overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: content }}/>
  </span> }

const serviceLinkGenerator = function () {
  var id = service.id;
  switch(service.type) {
      case 0: {
          return "#/service-calendar/" + id;
      } break;
      case "group-scheduled": {
          return "#/group-calendar/" + id;
      } break;
      case 1 : {
          return service.link;
      } break;
      case 2: {
          return service.link;
      } break;
      case 5 : {
          return "#/GSIPT/"+service.group_id+"/wizard/"+id;
      } break;
      case 6 : {
          return "#/BBSP/"+service.group_id+"/wizard/"+id;
      } break;
      case 7: {
          return "#/MSSP/" + service.group_id + "/wizard/" + id;
      } break;
      case 9: {
          return `#/FASTA/${service.group_id}/wizard/${id}`;
      } break;
      case 10: {
          return `#/walkup/${service.id}/wizard`;
      } break;
      default : {
          return "#/service/"+id;
      } break;
  }
}

  let serviceIcon = <Icon icon={([0, "group-scheduled"].includes(service.type)? "timeline-events" : "dollar")} color={(isHovered ? "white" : color)} size={32}/>

  return (<a className={Classes.CALLOUT} style={(isHovered ? hoveredStyle : buttonStyle)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} href={serviceLinkGenerator()}> <span style={{ fontSize: "0.8rem" }}>{service.group.name}</span> {serviceIcon}<span style={{ fontSize: "1rem" }}>{service.name}</span> </a> );
}

export default ServiceFavoriteButton;
