
'use strict';

import React, { useState, useCallback, useEffect } from 'react';
import moment from "moment";
import ServiceButton from "./ServiceButton";
import NewsItem from "./NewsItem";
import ManageNewsItems from "./ManageNewsItems";
import AppData from "data-modules/AppData";
import GroupData from "../Data/GroupData";
import DataManager from "data-modules/DataManager"
import UserData from "data-modules/UserData";
import SiteDialogData from "data-modules/SiteDialogData";
let DM = new DataManager();
import { Button, Dialog, H2, H3, Callout, Icon } from "@blueprintjs/core";
import ManagerGroupPermissionsEditor from "generic-components/ManagerGroupPermissionsEditor";
import ServiceUserTagManager from 'generic-components/ServiceUserTagManager';
import ServicesTree from './ServicesTree';

export default function GroupPage ({ params }) {

  let [services, setServices] = useState([]);
  let [news, setNews] = useState([]);
  let [group, setGroup] = useState([]);
  let [showPermissionManager, setShowingPermissionManager] = useState(false);
  let [showTagManager, setShowingTagManager] = useState(false);
  let [showHiddenNews, setShowHiddenNews] = useState(false);
  let [serviceFavoritesCollapsed, setServiceFavoritesCollapsed] = useState(true);
  let [serviceListCollapsed, setServiceListCollapsed] = useState(false);

  useEffect(() => {
    DM.add(GroupData.registerListener("RECORD_LOADED", onGroupLoaded), GroupData);
    DM.add(GroupData.registerListener("RECORD_LOAD_ERROR", onLoadError), GroupData);
    DM.add(GroupData.registerListener("SERVICES_LOADED", onServicesLoaded), GroupData);
    DM.add(GroupData.registerListener("SERVICES_LOAD_ERROR", onLoadError), GroupData);
    DM.add(GroupData.registerListener("NEWS_LOADED", onNewsLoaded), GroupData);
    DM.add(GroupData.registerListener("NEWS_LOAD_ERROR", onLoadError), GroupData);
    GroupData.getGroup(params.id);
    GroupData.getGroupServices(params.id);
    GroupData.getGroupNews(params.id, showHiddenNews);

    return () => { DM.clear() }
  }, [params.id])
  function onGroupLoaded (payload, action) {
    setGroup(payload);
  }
  function onServicesLoaded (payload, action) {
    setServices(payload);
  }
  function onNewsLoaded (payload, action) {
    setNews(payload);
  }
  function onLoadError (payload, action) {
    setServices([]);
  }

  function openPermissionManager() {
    setShowingPermissionManager(true)
  }
  function openServiceTagManager(service) { 
    setShowingTagManager(service)
  }

  function toggleServiceBookmark(service_id) { 
    let serviceIndex = services.findIndex(service => service.id == service_id);
    if(serviceIndex >= 0) {
      UserData.toggleServiceBookmark(service_id).then((res) => {
        let newServices = [...services];
        newServices[serviceIndex].is_bookmarked = res.bookmark_status;
        setServices(newServices);
      }, function (err) {
        console.error("Error toggling service bookmark:", err);
        SiteDialogData.open("Error toggling service bookmark", `${err}`)
      })
      
    }
  }



  var style={
    container : {
      padding: 5
    },
    service_container : {
      display : "flex",
      flexDirection : "column",
      width : "33%",
      padding: 5,
    },
    news_container : {
      display : "flex",
      flexDirection : "column",
      width: "66%",
      gap: 10,
      paddingTop: 5
    },
    title : {
      fontSize : "2.0rem",
      fontWeight : "bold",
      padding: "10px 20px",          
      backgroundColor : "#cecece",
      display : "flex",
      alignItems : "center",
      gap: 10,
      textShadow : "2px 2px 0px rgba(0, 0, 0, 0.25)",
      justifyContent : "space-between"
    }
  }

  if (group.status == 0) {
    return <div>
      <div style={style.container}>
          <center><H2> This group has been disabled. All related services are unavailable at this time. </H2></center>
      </div>
    </div>
  }

  let hasScheduledService = false;
  let isManager = AppData.authorize(20, group.id);
  // services.sort(function (a, b) {
  //   var displayWeight = b.display_weight - a.display_weight;
  //   if(displayWeight == 0) { return a.id-b.id; }
  //   return displayWeight;
  // }).map(function (service, index) {
  //   var shouldDisplay = true;
  //   if(parseInt(service.enabled,10) < 1) {shouldDisplay = false;}
  //   if(service.is_manager_only > 0 && !AppData.authorize(20, service.group_id)) { shouldDisplay = false; }
  //   if(service.type == 0) { hasScheduledService = true; }
  //   if(shouldDisplay) {
  //     serviceElements.push(<ServiceButton key={index} service={service} color={"gray"} onOpenTagManager={openServiceTagManager} />);
  //   }
  // });

  let _insertToPath = (root, pathComponents, service, depth) => {
    if(pathComponents.length == 0) { root._services.push(service); }
    if(pathComponents.length > 0 ) { root[pathComponents[0]] = _insertToPath((root[pathComponents[0]] || { _services: [] }), pathComponents.slice(1), service, depth + 1); }
    return root;
  }

  let serviceCount = 0;
  let servicesTree = services.sort(function (a, b) {
    var displayWeight = b.display_weight - a.display_weight;
    if(displayWeight == 0) { return a.id-b.id; }
    return displayWeight;
  }).filter((service) =>  {
    var shouldDisplay = true;
    if(parseInt(service.enabled,10) < 1) {shouldDisplay = false;}
    if(service.is_manager_only > 0 && !AppData.authorize(20, service.group_id)) { shouldDisplay = false; }
    if(service.type == 0) { hasScheduledService = true; }
    return shouldDisplay;
  }).reduce((tree, service, index) => {
    serviceCount++;
    let servicePath = service.display_path || "/";
    let filteredPathComponents = servicePath.split("/").filter(c => c.length > 0);
    return _insertToPath(tree, filteredPathComponents, service, 0);
  }, { _services: [] })

  // if(hasScheduledService) {
  //   serviceElements.unshift(<ServiceButton key={"gsv-button"} service={{ 
  //     id: group.id,
  //     group_id: group.id,
  //     type: "group-scheduled",
  //     name: "All Scheduled Resources",
  //     location: ""
  //   }}/>)
  // }

  var newsElements = [];
  if (news.length < 1) {
    var noNewsItem = {
      title : "No News",
      date : moment(),
      visible : 1,
      content : "This group has no news."
    };
    newsElements.push(<NewsItem key={0} item={noNewsItem}/>);
  } else {
    
    news.map(function (news_item, index) {
      newsElements.push(<NewsItem key={index} item={news_item} showControls={isManager} onEndEdit={() => { GroupData.getGroupNews(group.id, showHiddenNews); }}/>);
    });
  }

  let permissionManager = <span></span>;
    if(AppData.authorize(20, group.id)) {
      permissionManager = (<div style={{ display: "flex", gap: 15, alignItems : "center" }}>
        <Button text="Manage User Permissions" icon="user" onClick={openPermissionManager}/>
        <Dialog 
          isOpen={showPermissionManager}
          isCloseButtonShown={true}
          onClose={() => { setShowingPermissionManager(false); }}
          title={"Group User Permissions Manager"}
          style={{ width: "75%" }}
        >
          <ManagerGroupPermissionsEditor group={group}/>
        </Dialog>
      </div>);
    } 

  let tagManager = <span></span>;
  if(AppData.authorize(20, group.id)) {
    tagManager = <Dialog
      isOpen={showTagManager !== false}
      isCloseButtonShown={true}
      onClose={() => { setShowingTagManager(false) }}
      title={`${showTagManager?.name} - Tag Manager`}
      style={{ width: "50%" }}
    >
      <ServiceUserTagManager record={showTagManager}/>
    </Dialog>
  }

  var group_title = group.name || "Unknown";

  return (
    <div>
      <div style={style.container}>
        <div style={{ width: "100%", padding: 5 }}>
          <Callout key={0} style={style.title}>
            <div style={{ display: "flex", gap: 15, alignItems : "center" }}><Icon icon="office" size={36} style={{ color: "#BE0000" }}/>{group_title}</div>
            <div style={{ display: "flex", gap: 15, alignItems : "center" }}>{permissionManager}</div>
          </Callout>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <div key={1} style={style.service_container}>
            <div style={{ display: "flex", gap: 10, justifyContent: "center", borderBottom: "2px solid #BE0000" }} onClick={() => setServiceFavoritesCollapsed(!serviceFavoritesCollapsed)}>
              <Icon icon={("home")} size={24} style={{ color: "#BE0000" }}/>
              <H3> Bookmarked Services </H3>
              <Icon icon={( serviceFavoritesCollapsed ? "caret-up" : "caret-down")} size={24}/>
            </div>
            {( serviceFavoritesCollapsed ? <div style={{ height: 10 }}></div> : <div style={{ display: "flex", flexDirection: "column"}}>
                {services.filter(s => s.is_bookmarked == true).map((service, index) => (<ServiceButton key={`bookmark_${service.id}`} service={service} onOpenTagManager={openServiceTagManager} onToggleBookmark={toggleServiceBookmark}/>))}
            </div> )}
            <div style={{ display: "flex", gap: 10, justifyContent: "center", borderBottom: "2px solid #BE0000" }} onClick={() => setServiceListCollapsed(!serviceListCollapsed)}>
              <Icon icon={("lab-test")} size={24} style={{ color: "#BE0000" }}/>
              <H3> Available Services </H3>
              <Icon icon={( serviceListCollapsed ? "caret-up" : "caret-down")} size={24}/>
            </div>
            {(hasScheduledService && !serviceListCollapsed ? <><ServiceButton key={"gsv-button"} showBookmark={false} showInfo={false} showGroup={false} service={{ 
              id: group.id,
              group_id: group.id,
              type: "group-scheduled",
              name: "All Scheduled Resources",
              location: "",
            }}/><div style={{ height: 2, width: "100%", backgroundColor: "#BE0000", marginBottom: 5 }}></div></> : "")}
            {( serviceListCollapsed ? <div style={{ height: 10 }}></div> : <ServicesTree tree={servicesTree} openTagManagerAction={openServiceTagManager} toggleFavoriteAction={toggleServiceBookmark}/> )}
            {(serviceCount == 0 ? <span key={0}> No services available at this time.</span> : "")}
          </div>
          <div key={2} style={style.news_container}>
            <div style={{ display: "flex", gap: 10, justifyContent: "center", borderBottom: "2px solid #BE0000" }}>
              <Icon icon="feed" size={24} style={{ color: "#BE0000" }}/>
              <H3> News </H3>
            </div>
            <ManageNewsItems groupId={group.id} show={isManager} onEndEdit={() => { GroupData.getGroupNews(group.id); }}/>
            {newsElements}
          </div>
        </div>
        {tagManager}
      </div>
    </div>

  );
}
