import { useState, useEffect } from "react"
import UserData from "data-modules/UserData"
import { Icon, Callout } from "@blueprintjs/core"
import ServiceFavoriteButton from "./ServiceFavoriteButton"

export default function UserFavorites() {

    let [userFavorites, setUserFavorites] = useState([])
    let [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        UserData.getServiceBookmarks().then((data) => setUserFavorites(data.map((b) => b.service)))
    }, [])

    

    const style = {
        container : {
          // border : "1px solid black",
          // backgroundColor : "gray",
          padding : 20,
          margin : 5,
          borderRadius : 10,
        },
        title : {
          fontSize : "1.25rem",
          fontWeight: "bold",
          paddingBottom: 10,
          marginBottom: 10,
          color: "rgb(112, 142, 153)",
          borderBottom: `2px solid #BE0000`,
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          fontFamily: "Montserrat, sans-serif",
      
        },
        group_list : {
          display : "flex",
          gap: "10px",
          // width : "100%",
          flexBasis: "100px",
          flexWrap: "wrap",
          justifyContent: "center"
        }
      };

    return <div style={style.container}>
        <div style={style.title} onClick={() => setIsCollapsed(!isCollapsed)}>
            <Icon icon={"home"} style={{ color: "#BE0000" }} size={24}/> Bookmarked Services <Icon icon={( isCollapsed ? "caret-up" : "caret-down")} size={24}/>
        </div>
        <div>
            <div style={style.group_list}>
                {(isCollapsed ? <span></span> : userFavorites.map((service) => (<ServiceFavoriteButton key={service.id} service={service} color={service.color}/>)))}
                {(!isCollapsed && userFavorites.length === 0) && <Callout intent="primary">No bookmarked services found. To add a service to this list go to the facility and service desired and click the <Icon icon="star"/> icon.</Callout>}
            </div>
        </div>
    </div>
    
}