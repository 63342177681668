
'use strict';

import React from "react";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import {
  PopoverInteractionKind,
  Position,
  Button,
  Intent,
  H5,
  H2,
  FormGroup,
  ControlGroup,
  InputGroup,
  HTMLSelect,
  Callout,
  Label,
  ProgressBar,
  Popover,
  Classes,
  Tag
} from "@blueprintjs/core"
import { debounce } from "lodash"
import UserData from "data-modules/UserData"
import { PagedListSelect } from "generic-components/PagedListSelect";
import moment from "moment";
import { ProfilePictureManager } from "../ProfilePictureManager";

const accountTypeLabel = {
  [0] : {name:"On Campus", value:0, intent: "success"},
  [1] : {name:"Off Campus Educational/Federal", value:1, intent: "primary"},
  [2] : {name:"Commercial", value:2, intent: "warning"},
  [3] : {name:"Dummy", value:3, intent: "none"}
}

class UserAccountPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_results : [],
      account_results : [],
      user_filter_key : "full_name",
      user_filter_value : "",
      userSearchOpen: false,
      accountSearchOpen: false,
      isLoadingUsers: false,
      isLoadingAccounts: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.onUserListLoaded = this.onUserListLoaded.bind(this);
    this.onAccountListLoaded = this.onAccountListLoaded.bind(this);
    this.onUserSelected = this.onUserSelected.bind(this);
    this.openAccountsPopover = this.openAccountsPopover.bind(this);
    this.onAccountSelected = this.onAccountSelected.bind(this);
    this.updateUserFilterKey = this.updateUserFilterKey.bind(this);
    this.searchForUser = this.searchForUser.bind(this);
    this.onOpenAccountsPopover = this.onOpenAccountsPopover.bind(this);
    this.debouncedUserSearch = this.debouncedUserSearch.bind(this);
  }
  componentDidMount() {
    DM.add(UserData.registerListener("LIST_LOADED", this.onUserListLoaded), UserData);
    DM.add(UserData.registerListener("ACCOUNT_LIST_LOADED", this.onAccountListLoaded), UserData);
    if(this.props.user !== null && typeof this.props.user !== "undefined") {
      UserData.getActiveAccounts(this.props.user.id);
    }
  }
  componentWillUnmount() {
    DM.clear();
  }
  onUserListLoaded(users, action) {
    let self = this;
    self.setState({ user_results : users, isLoadingUsers: false });
  }
  onAccountListLoaded(accounts, action) {
    let self = this; 
    self.setState({ account_results : accounts, isLoadingAccounts: false });
  }
  onUserSelected(user, evt) {
    let self = this;
    if(typeof this.props.handlers.onUserSelected == "function") { this.props.handlers.onUserSelected(user, false); }
    self.setState({ accountSearchOpen: true, userSearchOpen: false });
    UserData.getActiveAccounts(user.id);
  }
  onOpenAccountsPopover() {
    this.setState({ isLoadingAccounts: true });
    if(this.props.user !== null && typeof this.props.user !== "undefined") { UserData.getActiveAccounts(this.props.user.id); }
  }
  openAccountsPopover() {
    let self = this;
    if(this.props.user !== null && typeof this.props.user !== "undefined") { UserData.getActiveAccounts(this.props.user.id); }
    self.setState({ accountSearchOpen: true, userSearchOpen: false, isLoadingAccounts: true });
  }
  onAccountSelected(account, evt) {
    let self = this;
    if(typeof this.props.handlers.onAccountSelected == "function") { this.props.handlers.onAccountSelected(account, true); }
    self.setState({ accountSearchOpen: false, userSearchOpen: false });
  }
  updateUserFilterKey(evt) {
    let self = this;
    var newValue = evt.target.value;
    self.setState({ user_filter_key : newValue });
  }

  debouncedUserSearch = debounce((newValue) => {
    UserData.recordSearch(this.state.user_filter_key, newValue);
  }, 500)

  searchForUser(evt) {
    let self = this;
    var newValue = evt.target.value;
    if(newValue.length < 3)  { self.setState({ user_results: [], user_filter_value : newValue }); return; }
    if(newValue == "") { self.setState({ user_results: [], user_filter_value : newValue }); return; }
    self.setState({ user_filter_value : newValue, isLoadingUsers: true });
    this.debouncedUserSearch(newValue);
  }
  render() {
    var self = this;
    var user = this.props.user;
    var account = this.props.account;
    var config = this.props.config;

    let elementWidth = config?.elementWidth || 300;

    var ownerName = "Select User";
    var accountName = "Select Account";

    var userSearchElement = self.props.displayOnly && <div></div> || <Button icon="search" disabled={true}/>;
    var accountSearchElement = self.props.displayOnly && <div></div> || <Button icon="search" disabled={true}/>;

    var userSelectContent = (<Callout style={{ marginTop: 10, padding: 10 }}>
      <H5> Select Owner </H5>
      <ControlGroup>
        <HTMLSelect value={self.state.user_filter_key} onChange={this.updateUserFilterKey}>
          <option value="full_name">Full Name (Partial Match)</option>
          <option value="email">Email (Partial Match)</option>
          <option value="username">Username (Partial Match)</option>
        </HTMLSelect>
        <InputGroup icon="people" label="Find User..." type="text" value={this.state.user_filter_value} onChange={this.searchForUser} fill={true}/>
      </ControlGroup>
      <div style={{marginTop: 5}}>
        {( this.state.isLoadingUsers ? <div style={{ paddingTop: 10, paddingBottom: 10, width: "100%" }}><ProgressBar/></div> : <PagedListSelect
        columns={[
          { label: "ID", data_key: "id" },
          { label: "Full Name", data_key: "full_name" },
          { label: "Email", data_key: "email" },
          { label: "Username", data_key: "username" }
        ]}
        records={self.state.user_results}
        onSelect={self.onUserSelected}
        activeRecord={user}/> )}
      </div>
      <ControlGroup><Button intent={Intent.WARNING} onClick={() => { self.setState({ userSearchOpen: false }); } }>Cancel</Button></ControlGroup>
    </Callout>);

    var accountSelectContent = (<Callout style={{ padding: 10 }}>
      <H5> Select Account </H5>
      <div style={{marginTop: 5}}>
      {( this.state.isLoadingAccounts ? <div style={{ paddingTop: 10, paddingBottom: 10, width: "100%" }}><ProgressBar/></div> : <PagedListSelect
          columns={[
            { label: "ID", data_key: "id" },
            { label: "Short Account", data_key: "short_account" },
            { label: "Type", data_key: "type", display_extract: (value) => { return accountTypeLabel[value].name || "Unk"; } },
            { label: "Title", data_key: "title" },
            { label: "Starting", data_key: "valid_from", display_extract: (value) => {
              if(value) {
                if(moment(self.props.comparisonDate).isBefore(value)) { return <span style={{ color: "red" }}>{moment(value).format("YYYY-MM-DD")}</span> ; }
                return moment(value).format("YYYY-MM-DD");
              } else { return "Unknown"; }
            } },
            { label: "Expires", data_key: "valid_to", display_extract: (value) => {
              if(value) {
                if(moment(self.props.comparisonDate).isAfter(value)) { return <span style={{ color: "red" }}>{moment(value).format("YYYY-MM-DD")}</span> ; }
                return moment(value).format("YYYY-MM-DD");
              } else { return "Unknown"; }
            } }
          ]}
          records={self.state.account_results}
          allowSelect={(act) => {
            if(self.props.allowedAccountTypes !== undefined) {
              if(![...self.props.allowedAccountTypes].includes(act.type)) { return false; } 
            }
            if(self.props.comparisonDate !== undefined) {
              let valid_to_date = moment(act.valid_to);
              let valid_from_date = moment(act.valid_from);
              if(moment(self.props.comparisonDate).isAfter(valid_to_date)) { return false; }
              if(moment(self.props.comparisonDate).isBefore(valid_from_date)) { return false; }
            }
            return true;
          }}
          onSelect={self.onAccountSelected}
          activeRecord={account}
          pageSize={20}/> )}
      </div>
      <ControlGroup><Button intent={Intent.WARNING} onClick={() => { self.setState({ accountSearchOpen: false }); } }>Cancel</Button></ControlGroup>
    </Callout>);


    if(typeof user !== "undefined" && user !== null) { ownerName = user.full_name; };
    if(typeof account !== "undefined" && account !== null) { accountName = account.title; };

    let userInputElement = <InputGroup type="text" style={{ width : elementWidth }} value={ownerName} readOnly={true} placeholder="Selected User"/>
    let accountInputElement = <InputGroup type="text" style={{ width : elementWidth }}  value={accountName} readOnly={true} placeholder="Selected Account"/>
    let accountTypeTag = <Tag intent={( accountTypeLabel[account?.type]?.intent || "danger" )}>{( accountTypeLabel[account?.type]?.name || "Unk" )}</Tag>;

    if( config?.allow_user_select ) {
      userSearchElement = <Popover isOpen={self.state.userSearchOpen} content={userSelectContent} interactionKind={PopoverInteractionKind.CLICK} placement="auto"><Button intent={Intent.PRIMARY} onClick={() => { self.setState({ userSearchOpen : !self.state.userSearchOpen }) }} icon="search"/></Popover>
      // userSearchElement = <Button intent={Intent.PRIMARY} onClick={() => { self.setState({ userSearchOpen: true, accountSearchOpen: false }); } } icon="search"/>
      userInputElement = <InputGroup type="text" style={{ width : elementWidth }} onClick={ () => {self.setState({ userSearchOpen : !self.state.userSearchOpen }) }} value={ownerName} intent={(ownerName == 'Select User' ? 'danger' : 'none')} readOnly={true} placeholder="Selected User"/>
    }

    if( config?.allow_account_select ) {
      accountSearchElement = <Popover isOpen={self.state.accountSearchOpen} content={accountSelectContent} onOpening={ self.onOpenAccountsPopover } interactionKind={PopoverInteractionKind.CLICK} placement="auto"><Button intent={Intent.PRIMARY} onClick={() => { self.setState({ accountSearchOpen : !self.state.accountSearchOpen }) }} icon="search"/></Popover>;
      // accountSearchElement = <Button intent={Intent.PRIMARY} onClick={self.openAccountsPopover} icon="search"/>;
      accountInputElement = <InputGroup type="text" style={{ width : elementWidth }} onClick={() => { self.setState({ accountSearchOpen : !self.state.accountSearchOpen }) }} intent={(accountName == 'Select Account' ? 'danger' : 'none')} rightElement={accountTypeTag} value={accountName} readOnly={true} placeholder="Selected Account"/>
    }

    let userInformationElement = <div  style={{ padding : 10 }}> Null User </div>;
    if(user !== null) {
      userInformationElement = <div>
        <H2> User Information </H2>
        <div style={{display: "flex"}}>
          <ProfilePictureManager displayOnly record={user} maxHeight="100%" />
          <div style={{padding: 4}}>
            <p> { (user.full_name ? user.full_name : "(None)") } </p>
            <p> { (user.phone_number ? user.phone_number : "(None)") } </p>
            <p> { (user.email ? user.email : "(None)") } </p>
          </div>
        </div>
      </div>
    }

    var ownerLabel = self.props.displayOnly && <H5>Owner</H5> || <Label>Owner</Label>;

    var userElement = (<FormGroup style={{ marginBottom : 5}}>
      {ownerLabel}
      {(<div>
        <ControlGroup>
          <Popover content={userInformationElement} popoverClassName={Classes.POPOVER_CONTENT_SIZING} interactionKind={PopoverInteractionKind.CLICK} position={Position.RIGHT}><Button icon="user"></Button></Popover>
          {userInputElement}
          {userSearchElement}
        </ControlGroup>
      </div>)}
    </FormGroup>);

    let accountDetailContent = <div style={{ padding : 10 }}> No Account Selected </div>;
    if(typeof account !== "undefined" && account !== null) {
      accountDetailContent = <div style={{ padding : 10 }}>
        <H2> Account Details </H2>
        <div>
          Short Account : {account.short_account}
        </div><div>
          Account Code : {account.PROJECT}{account.ACTCODE}
        </div><div>
          PI Name : {(account.user ? account.user.full_name : "(Not Loaded)")}
        </div><div>
          PI Email : {(account.user ? account.user.email : "(Not Loaded)")}
        </div>
      </div>
    }

    var accountLabel = self.props.displayOnly && <H5>Charge Account</H5> || <Label>Charge Account</Label>;

    var accountElement = (<FormGroup style={{ marginBottom : 5 }}>
      {accountLabel}
      {(<div>
        <ControlGroup>
          <Popover content={accountDetailContent} popoverClassName={Classes.POPOVER_CONTENT_SIZING} interactionKind={PopoverInteractionKind.CLICK} position={Position.BOTTOM_LEFT}><Button icon="dollar"></Button></Popover>
          {accountInputElement}
          {accountSearchElement}
        </ControlGroup>
      </div>)}
    </FormGroup>);

    var title = config?.title || "";

    if(config?.hide_title || title.length < 1 ) {
      return (<div>
        {userElement}
        {accountElement}
      </div>);
    } else {
      return (<div>
        <H2> {title} </H2>
        {userElement}
        {accountElement}
      </div>);
    }


  }
}

export default UserAccountPicker;
