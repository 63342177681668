
'use strict';

import React from "react";
import moment from "moment";
import UserAccountPicker from "generic-components/UserAccountPicker";
import OrderLine from "./OrderLine";
import StaticOrderLine from "./StaticOrderLine";
import ServicePresetData from "data-modules/ServicePresetData";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import UserData from "data-modules/UserData";
import AppData from "data-modules/AppData";
import { Button, Intent, H2, H3, HTMLTable } from "@blueprintjs/core";
import MarkdownIt from "markdown-it";
let markdown = new MarkdownIt({
  html: true
});

/*
  Line item order form for handling any billables that aren't covered by a custom order generator or an event scheduler
*/

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : null,
      account : null,
      date : moment(),
      presets : null,
      po_number : "",
      lines : []
    };

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.userLoaded = this.userLoaded.bind(this)
    this.ratesLoaded = this.ratesLoaded.bind(this)
    this.updateAccount = this.updateAccount.bind(this)
    this.onLineChange = this.onLineChange.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.addLine = this.addLine.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.updatePO = this.updatePO.bind(this)
    this.cancelForm = this.cancelForm.bind(this)
    this.removeLine = this.removeLine.bind(this)
  }
  
  componentDidMount() {
    DM.add(ServicePresetData.registerListener("LIST_LOADED", this.ratesLoaded), ServicePresetData);
    DM.add(UserData.registerListener("RECORD_LOADED", this.userLoaded), UserData);
    ServicePresetData.getRecordList(this.props.service.id);
    UserData.getRecord(AppData.get("userID"));
  }
  componentWillUnmount() {
    DM.clear();
  }
  userLoaded(user, action) {
    this.setState({'user' : user});
  }
  ratesLoaded(presets, action) {
    // Preset mapped to short form values for convenience when porting to line items.
    let defaultLines = presets.map(function(preset) {
      return {
        'quantity' : preset.min_quantity,
        'rate' : preset.per_item_rate,
        'commercial_rate' : preset.commercial_per_item_rate,
        'description' : preset.label,
        'type' : preset.type
      }
    });
    this.setState({'presets' : presets, 'lines' : defaultLines });
  }
  updateAccount(newAccount) {
    this.setState({ account : newAccount });
  }
  onLineChange(index, key, newValue) {
    let lines = this.state.lines;
    let line = lines[index];
    line[key] = newValue;
    lines[index] = line;
    this.setState({ 'lines' : lines });
  }
  resetForm() {
    if(confirm("Are you sure you want to reset the form?")) {
      let defaultLines = this.state.presets.map(function(preset) {
        return {
          'quantity' : preset.min_quantity,
          'rate' : preset.per_item_rate,
          'commercial_rate' : preset.commercial_per_item_rate,
          'description' : preset.label,
          'type' : preset.type
        }
      });
      this.setState({ 'lines' : defaultLines, 'user' : null, 'account' : null });
    }
  }
  addLine() {
    let lines = this.state.lines;
    lines.push({
      'quantity' : 0,
      'rate' : 0,
      'commercial_rate': 0,
      'description' : "",
      'type' : 0,
      'data_1' : "",
      'data_2' : "",
      'data_3' : "",
      'data_4' : "",
      'data_5' : ""
    });
    this.setState({'lines' : lines});
  }
  submitForm() {
    let self = this;
    let order_total = this.state.lines.reduce(function (prev_total, item, index, source_array) {
      if(self.state.account.type == 2) {
        return prev_total + (parseFloat(item.quantity) * parseFloat(item.commercial_rate));
      }
      return prev_total + (parseFloat(item.quantity) * parseFloat(item.rate));
    }, 0);
    // Order Compositing handled client side for endpoint simplicity on Line Item Order creation serverside.
    let orderData = {
      user : this.state.user,
      account : this.state.account,
      service : this.props.service,
      po_number : this.state.po_number,
      date : this.state.date,
      lines : this.state.lines,
      total : order_total
    };
    // Accepts a boolean response from the submit function as to whether to clear the form.
    let closeForm = this.props.onFormSubmit(orderData);
    if(closeForm) {
      this.cancelForm();
    }
  }
  updatePO(event) {
    let newValue = event.target.value;
    this.setState({ po_number : newValue});
  }
  cancelForm() {
    window.history.back();
  }
  removeLine(index) {
    let lines = this.state.lines;
    lines.splice(index, 1);
    this.setState({'lines' : lines});
  }
  render() {

    let style = {
      container : {
        margin : 5,
        display : "inline-block",
        width : "100%",
        fontSize : "1rem"
      },
      section : {
        backgroundColor : "rgba(0,0,0,0.1)",
        border : "1px solid gray",
        borderRadius : "5px",
        padding : 5,
        margin : 5
      },
      actionButton : {
        display : "inline-block",
        backgroundColor : "white",
        color : "black",
        padding : 3,
        margin : 3,
        borderRadius : 5,
        border : "2px solid black",
        cursor : "default",
        fontSize : "1rem",
        ":hover" : {
          backgroundColor : "gray"
        }
      }
    };

    let service = this.props.service;
    let self = this;


    let order_base = this.state.lines.reduce(function (prev_total, item, index, source_array) {
      if(self.state.account && self.state.account.type == 2) {
          return prev_total + (parseFloat(item.quantity) * parseFloat(item.commercial_rate));
      }
      return prev_total + (parseFloat(item.quantity) * parseFloat(item.rate));
    }, 0);
    let order_total = order_base;
    let order_multiplier = 1;
    if(service.group && this.state.account) {
      // Because the group multipliers change from year to year based on the account type we need to select the current correct one
      let multipliers = {
        0 : 1,
        1 : parseFloat(service.group.oce_multiplier),
        2 : parseFloat(service.group.commercial_multiplier),
      }
      if(multipliers[this.state.account.type]) {
        order_multiplier = multipliers[this.state.account.type];
      }
      
      // If the account to be charged has a override account multiplier, use that.
      if(this.state.account.account_multiplier !== 0) { order_multiplier = parseFloat(this.state.account.account_multiplier); }
    }
    order_total = order_base * parseFloat(order_multiplier);
    let isValid = true
    let lineErrors = [];
    let lineElements = this.state.lines.map(function (line, index) {
      // Not used much but this enables or disables the optional data fields.
      let optionals = [1,2,3,4,5].reduce(function(prev, index) {
        let enable_key = "enable_data_"+index;
        let label_key = "data_label_"+index;
        if(parseInt(service[enable_key], 10) == 1) {
          prev.push({ label : service[label_key], key : "data_"+index, data_index : index });
        }
        return prev;
      }, []);

      // Submission Validity Checks for the UI
      if(line.commercial_rate == 0) { isValid = false; lineErrors.push(`Line ${(index + 1)} - ${line.description} has a zero dollar commercial rate.`); }
      if(line.rate == 0) { isValid = false; lineErrors.push(`Line ${(index + 1)} - ${line.description} has a zero dollar internal rate.`); }
      const isCreditLine = (line.commercial_rate < 0 && line.rate < 0)
      if( !isCreditLine && (line.commercial_rate < (line.rate * parseFloat(service.group.oce_multiplier)))) { isValid = false; lineErrors.push(`Line ${(index + 1)} - ${line.description} has a commercial rate less than the F/A Minimum : $${(line.rate * parseFloat(service.group.oce_multiplier))}.`);  }
      
      if(line.description.length < 1) { isValid = false; }
      if(service.type === 4) { 
        let preset = self.state.presets[index];
        if(parseFloat(line.quantity) < parseFloat(preset.min_quantity)) { isValid = false; lineErrors.push(`Line ${(index + 1)} - ${line.description} is below the minimum quantity`); }
        if(parseFloat(preset.max_quantity) !== 0 && parseFloat(line.quantity) > parseFloat(preset.max_quantity)) { isValid = false; lineErrors.push(`Line ${(index + 1)} - ${line.description} is above the maximum quantity`); }
        return <OrderLine key={index} type={line.type} account={self.state.account} index={index} data={{...line, oce_multiplier: parseFloat(service.group.oce_multiplier)}} optionals={optionals} preset={preset} onChange={self.onLineChange} onRemove={self.removeLine}/>
      } else {
        return <OrderLine key={index} type={line.type} account={self.state.account} index={index} data={{...line, oce_multiplier: parseFloat(service.group.oce_multiplier)}} optionals={optionals} onChange={self.onLineChange} onRemove={self.removeLine}/>
      }
      
    });

    let controlElements = [<Button key={"form_control_reset"} className={Intent.WARNING} style={{ marginRight : 5 }} onClick={this.resetForm} text={"Reset Form"}/>];
    if(order_total == 0) { isValid = false; lineErrors.push("The order has a zero dollar total."); }
    if(self.state.user == null) { isValid = false; lineErrors.push("User Missing");  }
    if(self.state.account == null) { isValid = false; lineErrors.push("Account Missing");  }

    if(isValid) {
      controlElements.push(<Button key={"form_control_submit"} intent={"success"} style={{ marginRight : 5 }} onClick={this.submitForm} text={"Submit"}/>);
      controlElements.push(<Button key={"form_control_cancel"} intent={"danger"} style={{ marginRight : 5 }} onClick={this.cancelForm} text={"Cancel"}/>);
    } else {
      controlElements.push(<Button key={"form_control_submit"} disabled={true} style={{ marginRight : 5 }} text={"Submit"}/>);
      controlElements.push(<Button key={"form_control_cancel"} disabled={true} style={{ marginRight : 5 }} text={"Cancel"}/>);
    }

    let date_stamp = this.state.date.format("YYYY-MM-DD");

    let isManager = AppData.authorize(20, this.props.service.group_id);

    // Configuration for the user/account selector component
    let userAccountConfig = {
      allow_user_select : isManager,
      allow_account_select : true
    }

    let userAccountHandlers = {
      onUserSelected : (user, isValid) => {
        self.setState({
          user : user,
          account : null
        });

      },
      onAccountSelected : (account, isValid) => {
        self.setState({
          account : account
        });
      }
    }

    let serviceMessages = "No Information Available";
    if (service.service_notes !== null && `${service.service_notes}`.length > 0) { 
        serviceMessages = service.service_notes.trim() 
    }
    var messagesHTML = markdown.render(serviceMessages);

    switch(service.type) {
      case 3 : {
        return ( <div style={style.container}>
          <H2> Dynamic Order Form - {service.name} </H2>
          <div style={style.section}>
            <UserAccountPicker user={self.state.user} account={self.state.account} handlers={userAccountHandlers} config={userAccountConfig} comparisonDate={new Date()}/>
            <div> PO Number : <input value={this.state.po_number} placeholder="PO # (Optional)" onChange={this.updatePO}/> </div>
            <div> Order Date : {date_stamp} </div>
          </div>
          <div style={style.section}>
            <H3> Service Information </H3>
            <div dangerouslySetInnerHTML={{ __html: messagesHTML }}></div>
          </div>
          <div style={style.section}>
            <div> Order Lines </div>
            <HTMLTable bordered={true} compact={true}>
              <thead>
                <tr>
                  <th> Description </th>
                  <th> Quantity </th>
                  <th> Rate </th> 
                  <th> Line Total </th>
                </tr>
              </thead>
              <tbody>
                {lineElements}
              </tbody>
            </HTMLTable>
            <Button style={{ marginBottom : 5, marginRight : 5}} key={"add_line_action"} onClick={this.addLine} text={"Add Line"}/>
          </div>
          <div style={style.section}>
            Order Total : ${order_base.toFixed(2)} x {order_multiplier} = ${order_total.toFixed(2)}
          </div>
          <div style={{ ...style.section, color: "red", fontWeight: "bold" }}>
            {lineErrors.map(function(error, index) { return <div key={`line_error_message_${index}`}>{error}</div>; })}
          </div>
          <div style={style.section}>
            {controlElements}
          </div>
        </div> );
      } break;
      case 4 : {
        return ( <div style={style.container}>
          <H2> Static Order Form - {service.name} </H2>
          <div style={style.section}>
            <UserAccountPicker user={self.state.user} account={self.state.account} handlers={userAccountHandlers} config={userAccountConfig} comparisonDate={new Date()}/>
            <div> PO Number :  <input value={this.state.po_number} placeholder="PO # (Optional)" onChange={this.updatePO}/> </div>
            <div> Order Date : {date_stamp} </div>
          </div>
          <div style={style.section}>
            <H3> Service Information </H3>
            <div dangerouslySetInnerHTML={{ __html: messagesHTML }}></div>
          </div>
          <div style={style.section}>
            <H3> Order Lines </H3>
            <HTMLTable bordered={true} compact={true}>
              <thead>
                <tr>
                  <th> Description </th>
                  <th> Quantity </th>
                  <th> Rate </th> 
                  <th> Line Total </th>
                </tr>
              </thead>
              <tbody>
                {lineElements}
              </tbody>
            </HTMLTable>
          </div>
          <div style={style.section}>
            Order Total : ${order_base.toFixed(2)} x {order_multiplier} = ${order_total.toFixed(2)}
          </div>
          <div style={{ ...style.section, color: "red", fontWeight: "bold" }}>
            {lineErrors.map(function(error, index) { return <div key={`line_error_message_${index}`}>{error}</div>; })}
          </div>
          <div style={style.section}>
            {controlElements}
          </div>
          
        </div> );
      } break;
      default : {
        return ( <div style={style.container}>
          Invalid Service Type -- Unable to Create Form
        </div> );
      } break;
    }

  }
}

export default OrderForm;
