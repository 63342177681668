import { useState, useReducer } from "react"
import { Collapse, Button } from "@blueprintjs/core"
import ServiceButton from "./ServiceButton";
import ErrorBoundary from "generic-components/ErrorBoundary"

let NodeElement = ({ node, openTagManagerAction, toggleNode, nodePath, openNodes, toggleFavoriteAction }) => {
    let childPaths = Object.keys(node).filter((v => v !== "_services" )).sort();
    return <div>
        {childPaths.map((k, i) => {
            let joinedPath = nodePath.concat([k]).join("/")
            let isOpen = openNodes.includes(joinedPath);
            let childCount = node[k]["_services"].length || 0;
            return <div key={`node_${i}`}>
                <Button style={{ width: "100%", marginBottom: 5, fontWeight: "bold", height: 50 }} rightIcon={(isOpen  ? "caret-up" : "caret-down")} onClick={toggleNode.bind( null, node, nodePath.concat([k]) )}>{k} ({childCount})</Button>
                <Collapse isOpen={isOpen}>
                    <div style={{ paddingLeft: 20 }}><NodeElement node={node[k]} openTagManagerAction={openTagManagerAction} toggleNode={toggleNode} nodePath={nodePath.concat([k])} openNodes={openNodes} toggleFavoriteAction={toggleFavoriteAction}/></div>
                </Collapse>
            </div>;
        })}
        {(node._services || []).map((service) => {
            return <ServiceButton key={`service_button_${service.id}`} service={service} color={"gray"} onOpenTagManager={openTagManagerAction.bind(null, service)} onToggleBookmark={toggleFavoriteAction} />
        })}
    </div>
}

export default ({ tree, openTagManagerAction, toggleFavoriteAction }) => {
    
        let [openNodes, setOpenNodes] = useState([]);
        const [, forceUpdate] = useReducer(x => x + 1, 0);

        let toggleNode = (node, path) => {
            let joinedPath = path.join("/");
            if(openNodes.includes(joinedPath)) {
                openNodes.splice(openNodes.indexOf(joinedPath), 1);
            } else {
                openNodes.push(joinedPath)
            }
            setOpenNodes(openNodes)
            forceUpdate();
        }
        return <div> 
            <ErrorBoundary>
                <NodeElement node={tree} openTagManagerAction={openTagManagerAction} toggleFavoriteAction={toggleFavoriteAction} toggleNode={toggleNode} openNodes={openNodes} nodePath={[]}/>
            </ErrorBoundary>
        </div>
}